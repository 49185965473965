<template>
  <Modal v-model="modal" width="1000" :title="modalTitle" footer-hide :styles="{top: '30px'}">
    <Row class="m-b-10">
      <i-col span="2" class="p-t-5">标题</i-col>
      <i-col span="22">
        <Input v-model="additionalBean.name" placeholder="请输入商品描述的标题..." style="width: 400px" />
        <Button class="m-l-10" type="primary" @click="saveData">保存并提交</Button>
      </i-col>
    </Row>

    <TinyEditor v-if="modal" v-model="additionalHtml"></TinyEditor>
  </Modal>
</template>

<script>
import TinyEditor from '@/components/common/TinyEditor'
import { HTMLEncode, HTMLDecode } from '@/utils'
import { addOrEditAdditional } from '@/api/product/cloudinventory'

export default {
  components: {
    TinyEditor
  },
  data () {
    return {
      modal: false,
      isEdit: false,
      additionalHtml: '',

      additionalBean: {
        content: '',
        id: null,
        name: '',
        publisherId: this.$store.getters.token.userInfo.publisherId
      }
    }
  },
  methods: {
    showModal (params) {
      this.modal = true
      this.additionalHtml = ''

      if (params) {
        this.isEdit = true
        this.additionalBean.id = params.id
        this.additionalBean.name = params.name
        this.additionalHtml = HTMLDecode(params.content)
      } else {
        this.isEdit = false
        this.additionalBean.id = null
      }
    },
    saveData () {
      this.additionalBean.content = HTMLEncode(this.additionalHtml)

      addOrEditAdditional(this.additionalBean).then(res => {
        this.modal = false
        this.$Notice.success({ desc: this.isEdit ? '编辑商品描述成功！' : '新建商品描述成功！', title: '操作成功' })
        this.$emit('on-success')
      })
    }
  },
  computed: {
    modalTitle () {
      return this.isEdit ? '编辑商品描述' : '新建商品描述'
    }
  }
}
</script>
